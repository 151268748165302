var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-v flex-align-center w100"},[_c('div',{staticClass:"flex-v w100 flex-align-center ignore-product-header"},[_c('div',{staticClass:"ignore-fz32 bold ignore-mt120 z0"},[_vm._v("产品中心")]),_c('div',{staticClass:"ignore-fz16 ignore-mt8 uppercase c-cac z0"},[_vm._v(" products CENTER ")]),_c('div',{staticClass:"z0 ignore-color-line ignore-mt8"}),_c('div',{staticClass:"flex ignore-product-tab flex-nowrap"},_vm._l((_vm.productTypeList),function(item,index){return _c('div',{key:index,staticClass:"flex-v flex-1 flex-align-center ignore-type-item pointer",on:{"mouseenter":function($event){item.mouse = true},"mouseleave":function($event){item.mouse = false},"click":function($event){return _vm.getListByType(item)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.activeBg),expression:"activeBg"}],staticClass:"ignore-active-bg",class:_vm.activeProType.channelId == item.channelId
              ? 'active-bg-show'
              : item.mouse == true
              ? 'active-bg-show'
              : item.mouse == false
              ? 'active-bg-none'
              : 'active-bg-no'}),_c('div',{staticClass:"ignore-fz14 ignore-mt30"},[_vm._v(_vm._s(item.channelName))]),_c('div',{staticClass:"ignore-fz32 bold"},[_vm._v(_vm._s(item.cChildNum))])])}),0)]),_c('div',{ref:"productHeader",staticClass:"ignore-product-body flex-v flex-align-center"},[(_vm.showList)?_c('div',{staticClass:"flex-v flex-align-center"},[_c('div',{staticClass:"ignore-product-list flex flex-wrap"},_vm._l((_vm.productList),function(item,index){return _c('div',{key:index,staticClass:"ignore-product-item flex"},[_c('div',{staticClass:"ignore-item-icon-box flex flex-align-center flex-pack-center"},[_c('router-link',{attrs:{"to":{
                name: 'productCenter',
                query: {
                  typeId: _vm.activeProType.channelId,
                  pageIndex: _vm.pageIndex,
                  productId: item.proId,
                  name: item.proName,
                },
              },"target":"_blank"}},[_c('img',{staticClass:"ignore-item-icon",attrs:{"src":item.proImgUrl}})])],1),_c('div',{staticClass:"flex-v flex-1 relative"},[_c('router-link',{attrs:{"to":{
                name: 'productCenter',
                query: {
                  typeId: _vm.activeProType.channelId,
                  pageIndex: _vm.pageIndex,
                  productId: item.proId,
                  name: item.proName,
                },
              },"target":"_blank"}},[_c('div',{staticClass:"ignore-fz28 bold"},[_vm._v(_vm._s(item.proName))])]),_c('div',{staticClass:"ignore-mt30 ignore-fz14  max-line5 ignore-ml5-fz14",attrs:{"id":"line5"}},[_vm._v(" "+_vm._s(item.proIntro)+" ")]),_c('div',{staticClass:"ignore-more-box"},[_c('div',{staticClass:"relative ignore-more-btn-box",on:{"mouseenter":function($event){item.active = true},"mouseleave":function($event){item.active = false}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.active !== true),expression:"item.active !== true"}],staticClass:"animate__animated ignore-more-btn flex flex-align-center flex-pack-center ignore-fz16 pointer"},[_vm._v(" 更多 ")]),_c('router-link',{attrs:{"to":{
                    name: 'productCenter',
                    query: {
                      typeId: _vm.activeProType.channelId,
                      pageIndex: _vm.pageIndex,
                      productId: item.proId,
                      name: item.proName,
                    },
                  },"target":"_blank"}},[_c('div',{staticClass:"animate__animated ignore-more-btn-active flex flex-align-center flex-pack-center c-white ignore-fz16 pointer",class:item.active == true
                        ? 'animate__fadeIn'
                        : item.active == false
                        ? 'animate__fadeOut'
                        : 'more-hidden'},[_vm._v(" 更多 ")])])],1)])],1)])}),0),(_vm.totalRecord > 8)?_c('el-pagination',{staticClass:"ignore-mt20",attrs:{"background":"white","layout":"prev, pager, next","total":_vm.totalRecord,"current-page":_vm.pageIndex},on:{"current-change":_vm.handleCurrentChange}}):_vm._e()],1):_c('div',{staticClass:"flex"},[_c('div',{staticClass:"ignore-detail-list"},[_c('div',{staticClass:"ignore-fz20 bold"},[_vm._v("产品列表")]),_c('div',{staticClass:"flex-v w100 ignore-mt20 ignore-mb20"},_vm._l((_vm.productList),function(item,index){return _c('div',{key:index,staticClass:"ignore-detail-item flex flex-align-center pointer",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"ignore-detail-item-icon-box flex flex-align-center flex-pack-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.proImgUrl),expression:"item.proImgUrl"}],staticClass:"ignore-detail-item-icon"})]),_c('div',{staticClass:"flex-1 ignore-fz14 ignore-mr16"},[_vm._v(" "+_vm._s(item.proName)+" ")]),(item.proId == _vm.activeProduct.proId)?_c('div',{staticClass:"ignore-detail-active"}):_vm._e()])}),0)]),_c('div',{staticClass:"ignore-detail-box flex-v"},[_c('div',{staticClass:"text-center ignore-mt40 ignore-fz24 bold"},[_vm._v(" "+_vm._s(_vm.activeProduct.proName)+" ")]),_c('div',{staticClass:"ignore-html ignore-mt20",domProps:{"innerHTML":_vm._s(_vm.activeProduct.proDescription)}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }