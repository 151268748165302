<template>
  <div class="flex-v flex-align-center w100">
    <div
      class="flex-v w100 flex-align-center ignore-product-header"
    >
      <!-- :style="headerTop" -->
      <div class="ignore-fz32 bold ignore-mt120 z0">产品中心</div>
      <div class="ignore-fz16 ignore-mt8 uppercase c-cac z0">
        products CENTER
      </div>
      <div class="z0 ignore-color-line ignore-mt8"></div>
      <div class="flex ignore-product-tab flex-nowrap">
        <div
          class="flex-v flex-1 flex-align-center ignore-type-item pointer"
          v-for="(item, index) in productTypeList"
          :key="index"
          @mouseenter="item.mouse = true"
          @mouseleave="item.mouse = false"
          @click="getListByType(item)"
        >
          <img
            v-lazy="activeBg"
            class="ignore-active-bg"
            :class="
              activeProType.channelId == item.channelId
                ? 'active-bg-show'
                : item.mouse == true
                ? 'active-bg-show'
                : item.mouse == false
                ? 'active-bg-none'
                : 'active-bg-no'
            "
          />

          <div class="ignore-fz14 ignore-mt30">{{ item.channelName }}</div>
          <div class="ignore-fz32 bold">{{ item.cChildNum }}</div>
        </div>
      </div>
    </div>
    <div
      class="ignore-product-body flex-v flex-align-center"
      ref="productHeader"
      
    >
    <!-- :style="bodyTop" -->
      <div class="flex-v flex-align-center" v-if="showList">
        <div class="ignore-product-list flex flex-wrap">
          <div
            class="ignore-product-item flex"
            v-for="(item, index) in productList"
            :key="index"
          >
            <div
              class="ignore-item-icon-box flex flex-align-center flex-pack-center"
            >
              <router-link
                :to="{
                  name: 'productCenter',
                  query: {
                    typeId: activeProType.channelId,
                    pageIndex: pageIndex,
                    productId: item.proId,
                    name: item.proName,
                  },
                }"
                target="_blank"
                ><img :src="item.proImgUrl" class="ignore-item-icon"
              /></router-link>
            </div>
            <div class="flex-v flex-1 relative">
              <router-link
                :to="{
                  name: 'productCenter',
                  query: {
                    typeId: activeProType.channelId,
                    pageIndex: pageIndex,
                    productId: item.proId,
                    name: item.proName,
                  },
                }"
                target="_blank"
                ><div class="ignore-fz28 bold">{{ item.proName }}</div>
              </router-link>
              <div id ="line5"
                class="ignore-mt30 ignore-fz14  max-line5 ignore-ml5-fz14"
              >
                {{ item.proIntro }}
              </div>
              <div class="ignore-more-box">
                <div
                  class="relative ignore-more-btn-box"
                  @mouseenter="item.active = true"
                  @mouseleave="item.active = false"
                >
                  <div
                    class="animate__animated ignore-more-btn flex flex-align-center flex-pack-center ignore-fz16 pointer"
                    v-show="item.active !== true"
                  >
                    更多
                  </div>
                  <router-link
                    :to="{
                      name: 'productCenter',
                      query: {
                        typeId: activeProType.channelId,
                        pageIndex: pageIndex,
                        productId: item.proId,
                        name: item.proName,
                      },
                    }"
                    target="_blank"
                  >
                    <div
                      class="animate__animated ignore-more-btn-active flex flex-align-center flex-pack-center c-white ignore-fz16 pointer"
                      :class="
                        item.active == true
                          ? 'animate__fadeIn'
                          : item.active == false
                          ? 'animate__fadeOut'
                          : 'more-hidden'
                      "
                    >
                      更多
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          background="white"
          layout="prev, pager, next"
          :total="totalRecord"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          class="ignore-mt20"
          v-if="totalRecord > 8"
        >
        </el-pagination>
      </div>
      <div v-else class="flex">
        <div class="ignore-detail-list">
          <div class="ignore-fz20 bold">产品列表</div>
          <div class="flex-v w100 ignore-mt20 ignore-mb20">
            <div
              class="ignore-detail-item flex flex-align-center pointer"
              v-for="(item, index) in productList"
              :key="index"
              @click="toDetail(item)"
            >
              <div
                class="ignore-detail-item-icon-box flex flex-align-center flex-pack-center"
              >
                <img v-lazy="item.proImgUrl" class="ignore-detail-item-icon" />
              </div>
              <div class="flex-1 ignore-fz14 ignore-mr16">
                {{ item.proName }}
              </div>
              <div
                class="ignore-detail-active"
                v-if="item.proId == activeProduct.proId"
              ></div>
            </div>
          </div>
        </div>
        <div class="ignore-detail-box flex-v">
          <!-- <div class="ignore-fz14 c-39e pointer" @click="returnList">
            &lt; 返回上一级
          </div> -->
          <div class="text-center ignore-mt40 ignore-fz24 bold">
            {{ activeProduct.proName }}
          </div>
          <!-- <img v-lazy="activeProduct.icon" class="ignore-detail-icon" /> -->
          <div
            v-html="activeProduct.proDescription"
            class="ignore-html ignore-mt20"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
window.addEventListener("beforeunload", function () {
  window.scrollTo(0, 0);
});
import activeBg from "@/assets/home/active-bg.png";
import {
  getProductType,
  getProductList,
  getProductDetail,
} from "@/api/product";

export default {
  name: "productCenter",
  data() {
    return {
      activeBg,
      productTypeList: [],
      activeProType: {},
      headerTop: {
        marginTop: "0px",
        position: "relative",
      },
      bodyTop: {
        paddingTop: "0px",
      },
      productList: [],
      showList: true,
      activeProduct: {}, //当前选中产品详情
      pageIndex: 1,
      pageSize: 8,
      totalRecord: 0,
      activeTypeId: 0,
    };
  },
  methods: {
    productScroll() {
      if (this.$refs.productHeader.getBoundingClientRect().top == 70) {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      } else if (
        this.$refs.productHeader.getBoundingClientRect().top > -150 &&
        this.headerTop.position == "fixed"
      ) {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      } else if (this.$refs.productHeader.getBoundingClientRect().top < 220) {
        this.headerTop.marginTop = "-220px";
        this.headerTop.position = "fixed";
        this.bodyTop.paddingTop = "430px";
      } else {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      }
    },
    productScroll2() {
      if (this.$refs.productHeader.getBoundingClientRect().top == 70) {
        // this.headerTop.position = "relative";
        // this.headerTop.marginTop = "0px";
        // this.bodyTop.paddingTop = "0px";
      } else if (
        this.$refs.productHeader.getBoundingClientRect().top > -156 &&
        this.headerTop.position == "fixed"
      ) {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      } else if (this.$refs.productHeader.getBoundingClientRect().top < 150) {
        this.headerTop.marginTop = "-200px";
        this.headerTop.position = "fixed";
        this.bodyTop.paddingTop = "360px";
      } else {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      }
    },
    toDetail(item) {
      this.$router.push({
        path: "/productCenter",
        query: {
          typeId: this.activeProType.channelId,
          pageIndex: this.pageIndex,
          productId: item.proId,
          name: item.proName,
        },
      });
    },
    getDetail(item) {
      getProductDetail(item.proId).then((res) => {
        this.activeProduct = res[0];
      });
      this.showList = false;
      this.initScroll();
    },
    initScroll() {
      if (this.headerTop.position == "fixed") {
        window.scrollTo(0, 250);
      }
    },
    getProductType() {
      getProductType().then((res) => {
        let list = res;
        list.forEach((item) => {
          item.mouse = -1;
        });
        this.productTypeList = list;
        if (this.$route.query.typeId) {
          let typeItem = this.productTypeList.find(
            (item) => item.channelId == this.$route.query.typeId
          );
          this.getProList(typeItem);
        } else {
          this.getProList(list[0]);
        }
      });
    },
    handleCurrentChange(page) {
      this.pageIndex = page;
      this.getProList();
    },
    getProList(item) {
      if (item && item.channelId) {
        this.activeProType = item;
        if (this.$route.query.pageIndex && this.$route.query.pageIndex > 1) {
          this.pageIndex = this.$route.query.pageIndex;
        } else {
          this.pageIndex = 1;
        }
      }
      let param = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        ChannelId: this.activeProType.channelId,
      };
      getProductList(param).then((res) => {
        this.totalRecord = res.totalRecord;
        let list = res.objList;
        list.forEach((item) => {
          item.active = -1;
          item.proImgUrl = modeUrlObj.imgURL + item.proImgUrl;
        });
        this.productList = list;
        if (this.$route.query.productId) {
          let proItem = this.productList.find(
            (item) => item.proId == this.$route.query.productId
          );
          if (proItem && proItem.proId) {
            this.getDetail(proItem);
          }
        }
      });
      this.initScroll();
    },
    returnList() {
      this.showList = true;
      this.initScroll();
      this.activeProduct = {};
    },
    getListByType(item) {
      this.$router.push({
        path: "/productCenter",
        query: {
          typeId: item.channelId,
          name: item.channelName,
        },
      });
      // this.returnList();
      // this.getProList(item);
    },
  },
  destroyed() {
    if (window.screen.width <= 1366) {
      window.removeEventListener("scroll", this.productScroll2);
    } else {
      window.removeEventListener("scroll", this.productScroll);
    }
  },
  mounted() {
    if (window.screen.width <= 1366) {
      window.addEventListener("scroll", this.productScroll2);
    } else {
      window.addEventListener("scroll", this.productScroll);
    }
    this.getProductType();
  },
  activated() {
    this.productTypeList.forEach((item) => {
      item.mouse = -1;
    });
    this.productList.forEach((item) => {
      item.active = -1;
    });
    this.showList = true;
    this.activeProduct = {};
    window.scrollTo(0, 0);
    this.headerTop.position = "relative";
    this.headerTop.marginTop = "0px";
    if (this.productTypeList.length > 0 && this.$route.query.typeId) {
      let typeItem = this.productTypeList.find(
        (item) => item.channelId == this.$route.query.typeId
      );
      this.getProList(typeItem);
    }
  },
};
</script>

<style lang="scss" scoped>
.ignore-color-line {
  width: 50px;
  height: 3px;
  background: linear-gradient(-90deg, #34b5ff, #39e274);
  border-radius: 2px;
}
.ignore-product-header {
  z-index: 2;
  width: 100%;
  height: 390px;
  background: url("../assets/product/bg.jpg") no-repeat;
  background-size: 100% 320px;
  background-color: #f1f1f1;
  -webkit-transform: translateZ(0);
  left: 0;
  .ignore-product-tab {
    margin-top: 68px;
    width: 1500px;
    height: 100px;
    background: #fff;

    border-radius: 6px;
    .ignore-type-item {
      height: 100px;
      position: relative;
      .ignore-active-bg {
        position: absolute;
        top: 0;
        height: 100px;
        margin: 0 auto; /*水平居中*/
        left: 0; /*此处不能省略，且为0*/
        right: 0; /*此处不能省略，且为0*/
      }
    }
  }
}
.ignore-product-body {
  width: 100%;
  min-height: 600px;
  background: #f1f1f1;
  box-sizing: content-box;
  padding-bottom: 40px;
  .ignore-product-list {
    width: 1500px;
    .ignore-product-item {
      width: 745px;
      height: 355px;
      background: #ffffff;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 50px 50px 42px 12px;
      .ignore-item-icon-box {
        width: 260px;
        height: 260px;
        margin-right: 20px;
      }
      .ignore-item-icon {
        height: 230px;
        max-width: 230px;
      }
    }
    .ignore-product-item:nth-child(2n) {
      margin-right: 0;
    }
  }
}
.ignore-detail-list {
  width: 500px;
  max-height: 940px;
  background: #ffffff;
  border-radius: 6px;
  padding: 35px 19px 0px 19px;
  margin-right: 20px;
  overflow: auto;
  .ignore-detail-item {
    width: 462px;
    height: 102px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-bottom: none;
    .ignore-detail-item-icon-box {
      width: 60px;
      height: 60px;
      margin-left: 16px;
      margin-right: 27px;
      .ignore-detail-item-icon {
        height: 60px;
        max-width: 60px;
      }
    }
  }
  .ignore-detail-item:last-child {
    border-bottom: 1px solid #e2e2e2;
  }
  .ignore-detail-active {
    width: 4px;
    height: 102px;
    background: #39e274;
  }
}
.ignore-detail-box {
  width: 980px;
  min-height: 320px;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;
  .ignore-detail-icon {
    height: 400px;
    margin: 58px auto;
  }
  .ignore-html >>> img {
    max-width: 100%;
    margin: 0 auto;
  }
  // .ignore-html >>> td {
  //   border: 1px #e2e2e2 solid;
  // }
  .ignore-html {
    /deep/ table {
      border-spacing: 0;
      border-top: 1px solid #e2e2e2;
      border-left: 1px solid #e2e2e2;
      /deep/ tbody {
        /deep/ td {
          border-bottom: 1px solid #e2e2e2;
          border-right: 1px solid #e2e2e2;
        }
      }
    }
  }
}
.ignore-more-box {
  position: absolute;
  left: 0;
  bottom: 0px;
}
.ignore-more-btn-box {
  width: 150px;
  height: 50px;
  .ignore-more-btn {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: #ffffff;
    border: 1px solid #2a3038;
    color: #475669;
    position: absolute;
    left: 0;
    top: 0;
  }
  .ignore-more-btn-active {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: linear-gradient(
      -45deg,
      rgba(52, 181, 255, 0.83),
      rgba(57, 226, 116, 0.83)
    );
    box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
    border: none;
    position: absolute;
    left: 0;
    top: 0;
  }
  .more-hidden {
    opacity: 0;
  }
}
.active-bg-show {
  width: 100%;
  animation: moveShow 0.7s;
}
.active-bg-none {
  width: 0px;
  animation: moveNone 0.7s;
}
.active-bg-no {
  width: 0px;
}
#line5{
  color:#5d5858;
  line-height: 1.8em;
  -webkit-line-clamp: 4;
}
@keyframes moveShow {
  0% {
    width: 10px;
  }
  100% {
    width: 100%;
  }
}
@keyframes moveNone {
  0% {
    width: 100%;
  }
  100% {
    width: 0px;
  }
}
</style>
<!-- <style scoped>
@media (max-width: 1366px) {
  .ignore-product-header {
    height: 310px !important;
    background-size: 100% 260px !important;
  }
  .ignore-product-tab {
    margin-top: 48px !important;
    width: 900px !important;
    height: 70px !important;
  }
  .ignore-type-item {
    height: 70px !important;
  }
  .ignore-active-bg {
    height: 70px !important;
  }
  .ignore-product-header .ignore-mt120 {
    margin-top: 100px !important;
  }
  .ignore-product-header .ignore-mt30 {
    margin-top: 12px !important;
  }
  .ignore-product-header .ignore-fz32 {
    font-size: 26px !important;
  }
  .ignore-product-list {
    width: 900px !important;
  }
  .ignore-product-item {
    width: 445px !important;
    height: 272px !important;
    background: #ffffff;
    padding: 30px 20px 32px 10px !important;
  }
  .ignore-item-icon-box {
    width: 180px !important;
    height: 180px !important;
    margin-top: 20px !important;
  }
  .ignore-item-icon {
    height: 180px !important;
    max-width: 180px !important;
  }
  .ignore-product-item .ignore-fz28 {
    font-size: 20px !important;
  }
  .ignore-product-item .ignore-mt30 {
    margin-top: 12px !important;
  }
  .ignore-product-item .ignore-fz14 {
    font-size: 12px !important;
  }
  .ignore-more-btn-box {
    width: 120px !important;
    height: 40px !important;
  }
  .ignore-more-btn {
    width: 120px !important;
    height: 40px !important;
  }
  .ignore-more-btn-active {
    width: 120px !important;
    height: 40px !important;
  }
  .ignore-product-item .ignore-fz16 {
    font-size: 14px !important;
  }
  .ignore-detail-list {
    width: 230px !important;
    max-height: 440px !important;
    padding: 20px 12px 0px 12px !important;
    margin-right: 16px !important;
  }
  .ignore-detail-list .ignore-fz20 {
    font-size: 16px !important;
  }
  .ignore-detail-list .ignore-mt20 {
    margin-top: 12px !important;
  }
  .ignore-detail-item {
    width: 100% !important;
    height: 72px !important;
  }
  .ignore-detail-item-icon-box {
    width: 40px !important;
    height: 40px !important;
    margin-left: 10px !important;
    margin-right: 17px !important;
  }
  .ignore-detail-item-icon {
    height: 40px !important;
    max-width: 40px !important;
  }
  .ignore-detail-active {
    width: 4px;
    height: 72px !important;
  }
  .ignore-detail-box {
    width: 654px !important;
  }
  .ignore-detail-box .ignore-fz24 {
    font-size: 20px !important;
  }
}
</style> -->